
.theory-list{
    padding: 0px 20px;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    .theory-list-header{
        display: flex;
        align-items: center;
        span{
            line-height: 1;
        }
    }
}
.goods-wrapper {
    display: inline-flex;
    align-items: center;
    .goods-cover {
        width: 100px;
        height: 40px;
        display: flex;
        background: #F6F6F6;
        img  {
            max-width: 100%;
            max-height: 100%;
            margin: auto;
        }
    }
    .goods-name {
        width: 1%;
        flex: 1;
        margin-left: 10px;
        font-size: 16px;
    }
}

.computer-banner {
    width: 100%;
    /*height: 436px;*/
    height: 395px;
    .banner-img {
        display: block;
        /*width: calc(100% - 180px);*/
        /*max-height: 630px;*/
        max-width: 100%;
        max-height: 100%;
        margin: 29px auto 0;
    }
    ::v-deep .swiper-wrapper {
        height: calc(100% - 20px) !important;
        .swiper-slide {
            display: flex;
            justify-content: center;
            align-items: center;
            height: calc(100% - 20px) !important;
        }
    }
    ::v-deep .swiper-pagination {
        position: relative;
        bottom: 0;
    }
    ::v-deep .swiper-pagination-bullets {
        .swiper-pagination-bullet {
            width: 30px;
            height: 3px;
            background: #2C405F;
            border-radius: 0;
        }
    }
    .no-img {
        width: 264px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .banner-swiper-btn-prev,
    .banner-swiper-btn-next {
        width: 90px;
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
        opacity: .5;
        cursor: pointer;
        img {
            display: block;
            width: 20px;
        }
        &:hover {
            opacity: 1;
        }
    }
    .banner-swiper-btn-prev {
        left: 0;
        i {
            color: #BFBFBF;
            font-size: 30px;
        }
    }
    .banner-swiper-btn-next {
        right: 0;
        i {
            color: #BFBFBF;
            font-size: 34px;
        }
    }
}
